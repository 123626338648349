import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Info as QuestionMarkIcon } from "@material-ui/icons";
import InfoModal from "components/InfoModal";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 100,
    justifyContent: "space-between",

    "& iframe": {
      borderRadius: 10,
    },
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 16,
  },
  swapHoriz: {
    height: 48,
    width: 48,
  },
  iconButton: {},
}));

const Moonpay: React.FC = () => {
  const classes = useStyles();
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  return (
    <Box className={classes.main}>
      <InfoModal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        title="Purchasing crypto with credit card powered by MoonPay"
        content="This page allows you to purchase Cryptocurrency using credit card directly via one of our partners - MoonPay.
        By filling in your ERC20 wallet address in the process, the crypto asset you purchase will be sent to your crypto wallet after the purchase is completed."
      />
      <Box width="100%" mb={1}>
        <Box className={classes.buttonWrapper}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              setInfoModalOpen(true);
            }}
          >
            <QuestionMarkIcon />
          </IconButton>
        </Box>
        <iframe
          src="https://buy-sandbox.moonpay.com?apiKey=pk_test_paGeiSjYZBriXHA5WN8V2R5Ugmc6zp5R"
          width="100%"
          height={800}
          scrolling="no"
          frameBorder="none"
          title="eth-polygon"
        />
      </Box>
    </Box>
  );
};

export default Moonpay;
