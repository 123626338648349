import { useState } from "react";
import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContainedButton } from "components";
import { Info as QuestionMarkIcon } from "@material-ui/icons";
import InfoModal from "components/InfoModal";

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 100,
    justifyContent: "space-between",

    "& iframe": {
      borderRadius: 10,
    },
  },
  swapHoriz: {
    height: 48,
    width: 200,
    background: "#8635db",

    "&:hover": {
      background: "#a73bed",
    },
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 16,
  },
  iconButton: {
    marginLeft: 8,
  },
}));

const Swap: React.FC = () => {
  const classes = useStyles();

  const [url, setUrl] = useState(
    "https://app.sushi.com/swap?tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&tokens=0x071AC29d569a47EbfFB9e57517F855Cb577DCc4C&chainId=137"
  );
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  return (
    <Box className={classes.main}>
      <InfoModal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        title="GCOIN/WETH swap provided by Sushiswap"
        content="This page is for you to swap ETH on Polygon to GCOIN or vise versa.
        Only use this tool if you have already bridge ETH over to Polygon and have MATIC available to pay for gas fee."
      />
      <Box className={classes.buttonWrapper}>
        <ContainedButton
          fullWidth
          className={classes.swapHoriz}
          color="secondary"
          size="small"
          label="Change Swap Direction"
          onClick={() => {
            if (
              url ===
              "https://app.sushi.com/swap?tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&tokens=0x071AC29d569a47EbfFB9e57517F855Cb577DCc4C&chainId=137"
            ) {
              setUrl(
                "https://app.sushi.com/swap?tokens=0x071AC29d569a47EbfFB9e57517F855Cb577DCc4C&tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&chainId=137"
              );
            } else {
              setUrl(
                "https://app.sushi.com/swap?tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&tokens=0x071AC29d569a47EbfFB9e57517F855Cb577DCc4C&chainId=137"
              );
            }
          }}
        />
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setInfoModalOpen(true);
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      </Box>
      <Box width="100%" mb={1}>
        <iframe
          src={url}
          width="100%"
          height={800}
          scrolling="no"
          title="eth-polygon"
          frameBorder="none"
        />
      </Box>
    </Box>
  );
};

export default Swap;
