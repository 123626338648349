import { useState } from "react";
import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContainedButton } from "components";
import { Info as QuestionMarkIcon } from "@material-ui/icons";
import InfoModal from "components/InfoModal";

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 100,
    justifyContent: "space-between",

    "& iframe": {
      borderRadius: 10,
    },
  },
  swapHoriz: {
    height: 48,
    width: 200,
    background: "#8635db",

    "&:hover": {
      background: "#a73bed",
    },
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 16,
  },
  iconButton: {
    marginLeft: 8,
  },
}));

const Swap: React.FC = () => {
  const classes = useStyles();

  const [url, setUrl] = useState(
    "https://umbria.network/widgetv2/?ref=yhbpEYzwArszTQg2&route=ethmatic&asset=matic"
  );
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  return (
    <Box className={classes.main}>
      <InfoModal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        title="Polygon bridge powered by Umbria"
        content="This page is for you to bridge over your crypto asset (such as ETH, MATIC) from Ethereum Blockchain to Polygon Blockchain, or the other way round.
Bridging ETH and MATIC over to Polygon is the first step if you want to swap for GCOIN."
      />
      <Box className={classes.buttonWrapper}>
        <ContainedButton
          fullWidth
          className={classes.swapHoriz}
          color="secondary"
          size="small"
          label="Change Bridge Direction"
          onClick={() => {
            if (
              url ===
              "https://umbria.network/widgetv2/?ref=yhbpEYzwArszTQg2&route=ethmatic&asset=matic"
            ) {
              setUrl(
                "https://umbria.network/widgetv2/?ref=yhbpEYzwArszTQg2&route=maticeth&asset=eth"
              );
            } else {
              setUrl(
                "https://umbria.network/widgetv2/?ref=yhbpEYzwArszTQg2&route=ethmatic&asset=matic"
              );
            }
          }}
        />
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setInfoModalOpen(true);
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      </Box>
      <Box width="100%" mb={1}>
        <iframe
          src={url}
          width="100%"
          height={800}
          scrolling="no"
          frameBorder="none"
          title="eth-polygon"
        />
      </Box>
    </Box>
  );
};

export default Swap;
