import React from "react";
import {
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useDarkModeManager } from "state/user/hooks";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  page: {
    backgroundColor: "transparent",
    width: "calc(100% - 127px)",
    minHeight: "100vh",
  },

  divider: {
    height: "48px",
  },

  account: {
    padding: 0,
    height: 45,
    border: `1px solid ${palette.divider}`,
    backgroundColor: palette.success.main,
    borderRadius: 30,
    cursor: "pointer",
    color: palette.text.primary,

    "& a": {
      textDecoration: "none",
    },

    "&> div:hover:not(:active)": {
      "&> svg path": {
        fill: palette.text.primary,
      },

      "& p": {
        color: palette.text.primary,
      },
    },
  },

  accountInfo: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 6,
  },

  accountMobile: {
    height: 45,
    width: "100%",
    backgroundColor: palette.success.main,
    border: `1px solid ${palette.divider}`,
    borderRadius: 30,
    color: palette.text.primary,

    "& $disconnect": {
      width: 43,
      flex: "none",
    },

    "& a": {
      textDecoration: "none",
    },

    "&> div:hover:not(:active)": {
      "&> svg path": {
        fill: palette.text.primary,
      },

      "& p": {
        color: palette.text.primary,
      },
    },
  },

  connect: {
    padding: "0 12px",
    height: 45,
    border: `1px solid ${palette.divider}`,
    borderRadius: 30,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },

  disconnect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 10px 0 12px",

    "& svg path": {
      fill: palette.text.primary,
    },

    "&:hover:not(:active)": {
      "& svg path": {
        fill: palette.text.primary,
      },
    },

    "& button": {
      padding: 0,

      "& svg path": {
        fill: palette.text.primary,
      },

      ":active": {
        borderColor: palette.primary.main,

        "& svg path": {
          fill: palette.text.primary,
        },

        "& p": {
          color: palette.text.primary,
        },
      },
    },
  },

  address: {
    color: palette.text.primary,
    fontSize: 14,
  },

  addressMobile: {
    color: palette.text.primary,
    fontSize: 14,
    marginLeft: 12,
  },

  button: {
    margin: "0 10px 0 0",
    height: 45,
    "& svg": {
      marginLeft: 8,
    },
  },

  fullWidth: {
    margin: 0,
    width: "100%",
  },

  connectWalletButton: {
    fontSize: 14,
  },
  walletConnect: {
    display: "flex",
    background: palette.background.paper,
    borderRadius: 30,
    whiteSpace: "nowrap",
    alignItems: "center",
    padding: "8px 11px 8px 20px",

    "& p": {
      marginRight: 28,
    },
  },
  dot: {
    backgroundColor: palette.success.main,
    minWidth: 10,
    height: 10,
    borderRadius: "50%",
    marginRight: 12,
  },
  title: {
    fontSize: 30,
    lineHeight: "37px",
  },
  subTitle: {
    fontSize: 20,
    color: palette.text.secondary,
    fontWeight: 400,
  },
}));

interface AccountButtonsProps {
  mobile?: boolean;
  onHide?: () => void;
}

const AccountButtons: React.FC<AccountButtonsProps> = ({ mobile }) => {
  const [darkMode] = useDarkModeManager();
  const classes = useStyles({ darkMode, mobile });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={!mobile ? "space-between" : "center"}
      id="here"
    >
      {mobile && <Divider className={classes.fullWidth} />}
      <Typography className={classes.title}>BUY GCOIN</Typography>
    </Grid>
  );
};

export default AccountButtons;
