import React, { Suspense } from "react";
import { Provider as StateProvider } from "react-redux";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";

import "animate.css/animate.css";
import { darkTheme } from "./theme";
import store from "./state";
import { PageWithSidebar } from "layouts";
import { Bridge, Moonpay, Swap, Landing } from "./pages";
import UserUpdater from "./state/user/updater";

const StateUpdaters: React.FC = () => {
  return (
    <>
      <UserUpdater />
    </>
  );
};

const ThemeProvider: React.FC = ({ children }) => {
  const location = useLocation();
  let theme = darkTheme;

  if (location.pathname.replace("/", "") === "") {
    theme = darkTheme;
  }

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

const Providers: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <StateProvider store={store}>
          <StateUpdaters />

          <ThemeProvider>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StateProvider>
      </Suspense>
    </BrowserRouter>
  );
};

const App: React.FC = () => {
  return (
    <Providers>
      <Switch>
        <Route exact path="/swap">
          <>
            <PageWithSidebar>
              <Swap />
            </PageWithSidebar>
          </>
        </Route>
        <Route exact path="/bridge">
          <>
            <PageWithSidebar>
              <Bridge />
            </PageWithSidebar>
          </>
        </Route>
        <Route exact path="/moonpay">
          <>
            <PageWithSidebar>
              <Moonpay />
            </PageWithSidebar>
          </>
        </Route>
        <Route exact path="/">
          <>
            <Landing />
          </>
        </Route>
      </Switch>
    </Providers>
  );
};

export default App;
