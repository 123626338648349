import React from "react";
import { Typography, Modal, Box, Fade, Backdrop } from "@material-ui/core";

import { ModalContainer } from "components";

export interface InfoModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string;
}

const InfoModal: React.FC<InfoModalProps> = ({ open, onClose, title, content }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContainer size="sm">
          <Box width={1} marginBottom={2}>
            <Typography variant="h5">{title}</Typography>
          </Box>

          <Box marginX="auto">
            <Typography variant="body2">
              {content}
            </Typography>
          </Box>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

export default InfoModal;
