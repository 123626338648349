import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";

const useStyles = makeStyles(({ palette }) => ({
  inactiveSwitch: {
    cursor: "pointer",
    width: "100%",
    height: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
    marginBottom: 8,

    "& svg path": {
      fill: palette.secondary.main,
    },
    "& .MuiTypography-root": {
      fontWeight: 400,
      lineHeight: "24px",
      fontSize: "24px",
      color: palette.secondary.main,
    },
    "&:hover": {
      "& svg path": {
        fill: palette.text.primary,
      },
      "& .MuiTypography-root": {
        fontWeight: 400,
        fontSize: "24px",
        color: palette.text.primary,
      },
    },
  },
  activeSwitch: {
    cursor: "default",
    background: "#333363",
    borderRadius: 20,
    position: "relative",
    boxShadow: "0px 4px 25px #333363",

    "& svg path": {
      fill: palette.common.white,
    },
    "& .MuiTypography-root": {
      color: palette.primary.main,
      fontWeight: 700,
    },
  },

  dot: {
    width: 10,
    height: 10,
    background: "#8989EB",
    borderRadius: "50%",
    position: "absolute",
    top: 12,
    right: 12,
  },
  disabled: {
    cursor: "default",

    "& svg path": {
      fill: palette.text.secondary,
    },

    "& .MuiTypography-root": {
      color: palette.text.secondary,
    },

    "&:hover": {
      "& svg path": {
        fill: palette.text.secondary,
      },
      "& .MuiTypography-root": {
        color: palette.text.secondary,
      },
    },
  },
}));

export interface SidebarItemProps {
  title: string;
  link?: string | undefined;
  Icon: any;
  href?: boolean;
  disabled?: boolean;
  requireAccount?: boolean;
  onHide?: () => void;
  activeCondition?: any;
  onClick?: (() => void) | undefined;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  title,
  link,
  Icon,
  href,
  disabled,
  requireAccount,
  onClick,
  onHide,
  activeCondition,
}) => {
  const location = useLocation();
  const active = location.pathname === link;
  const classes = useStyles({ active });
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!href) {
      if (link) {
        history.push(link, { previous: location.pathname });
        if (onHide) {
          onHide();
        }
      } else if (onClick) {
        onClick();
      }
    } else {
      window.open(link, "_blank");
    }
  };

  const isDisabled = disabled || requireAccount;
  const WrapperComponent: any = isDisabled ? Tooltip : React.Fragment;

  return (
    <WrapperComponent
      {...(isDisabled
        ? { title: disabled ? "Disabled on testnet" : "Connect" }
        : {})}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        height="47px"
        className={cx(
          classes.inactiveSwitch,
          activeCondition && classes.activeSwitch,
          isDisabled && classes.disabled
        )}
        onClick={isDisabled ? () => {} : handleClick}
      >
        {Icon}
        {activeCondition && <Box className={classes.dot}></Box>}
        <Typography>{title}</Typography>
      </Box>
    </WrapperComponent>
  );
};

export default SidebarItem;
