import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContainedButton } from "components";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100vh",
  },
  button: {
    height: 48,
    width: 300,
    background: "#8635db",
    marginBottom: 8,

    "&:hover": {
      background: "#a73bed",
    },
  },
  title: {
    fontSize: 32,
    marginBottom: 32
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    margin: "auto",
  },
}));

const Landing: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.main}>
      <Box className={classes.buttonWrapper}>
        <Typography className={classes.title}>BUY GCOIN</Typography>
        <ContainedButton
          fullWidth
          className={classes.button}
          color="secondary"
          size="small"
          label="Purchase with Cryptocurrency"
          onClick={() => {
            history.push('/swap');
          }}
        />
        <ContainedButton
          fullWidth
          className={classes.button}
          color="secondary"
          size="small"
          label="Purchase with Fiat"
          onClick={() => {
            history.push('/moonpay');
          }}
        />
      </Box>
    </Box>
  );
};

export default Landing;
